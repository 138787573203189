import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

const Imprint = () => {
  return (
    <React.Fragment>
      <PageTitle title={"Imprint"} />
      <Layout>
        <div className="imprint-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Imprint</a>
              </li>
            </ul>
          </div>
          <div className="imprint">
            <h1>Imprint</h1>
            <div className="imprint__block">
              <div className="imprint__block--info">
                Responsible for the content: <br />
                Cyber Trust Services GmbH <br />
                Wienerbergstraße 11 / 12A <br />
                A-1100 Vienna <br />
                Mail:{" "}
                <a href="mailto:office@cyber-trust.at">office@cyber-trust.at</a>
                <br />
                Commercial register number: FN 535636a <br />
                CEO: Dr. Thomas Stubbings, MBA <br />
              </div>
            </div>
            <div className="text">
              <p>
                Legal disclaimer: Despite careful research and verification,
                errors in the content of this website cannot be ruled out.{" "}
                <a href="mailto:office@cyber-trust.at">office@cyber-trust.at</a>{" "}
                will be happy to receive information and corrections. CTS Cyber
                Trust Services GmbH assumes no liability for any damage or
                consequences resulting from the direct or indirect use of the
                content.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default Imprint
